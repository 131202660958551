<template>
  <div class="sticky top-0 z-10">
    <div class="bg-haiti h-80px text-white">
      <div class="flex justify-between items-center h-full">
        <div v-if="companyInfo" class="flex items-center flex-wrap">
          <div class="px-10">
            <the-logo></the-logo>
          </div>
        </div>

        <div class="flex items-center flex-nowrap flex-none">
          <div class="px-10 w-maxContent mobile:hidden">
            <the-header-menu class="flex-none"></the-header-menu>
          </div>

          <div class="flex items-center flex-wrap px-10 mobile:hidden">
            <the-language></the-language>
          </div>

          <div v-if="!routePath.includes('my-page')" class="flex items-center flex-wrap px-10 mobile:hidden">
            <the-header-cart></the-header-cart>
          </div>

          <div v-if="userFullName" class="flex items-center flex-wrap pr-10">
            <the-header-profile></the-header-profile>
          </div>

          <div class="hidden mobile:flex mr-4 flex">
            <button @click="showMobileMenu = true">
              <img src="@/components/forms/assets/Icons/mobile/menu-white.svg" alt="">
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showMobileMenu" class="fixed w-screen h-screen bg-white top-0 flex flex-col justify-between">
      <div>
        <div class="flex justify-end">
          <button @click="showMobileMenu = false" class="mr-6 mt-6">
            <img src="@/components/forms/assets/Icons/M-size/x.svg" alt="">
          </button>
        </div>
        <div class="mx-4 mt-6 mb-10 text-base font-semibold">
          <router-link
            v-if="routePath.includes('my-page')"
            :to="{name: 'home'}"
            class="bg-gray-100 rounded w-full flex justify-between px-6 py-4"
          >
            <span>{{$t('word_to_booking')}}</span>
            <img src="@/components/forms/assets/Icons/chevron/right.svg" alt="">
          </router-link>
          <router-link v-else
            :to="{name: 'account'}"
            class="bg-gray-100 rounded w-full flex justify-between px-6 py-4"
          >
            <span>{{$t('word_my-page')}}</span>
            <img src="@/components/forms/assets/Icons/chevron/right.svg" alt="">
          </router-link>
        </div>
        <div>
          <p class="text-base font-medium mb-4 mx-4">{{ $t('select-language') }}</p>
          <the-language></the-language>
        </div>
      </div>
      <UButton
        v-if="route.name !== 'login'"
        solid
        rounded=""
        :label="isAuthorized ? $t('word_logout') : $t('pg-title_login')"
        :preset="undefined"
        :border-width="1"
        :color="'white'"
        :text-color="'gray-900'"
        :font-weight="600"
        :padding="'py-4'"
        :border-color="'gray-900'"
        class="m-4"
        @click="isAuthorized ? logout() : login()"
      ></UButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TheLanguage from "@/components/the-language.vue";
import TheHeaderMenu from "@/components/the-header/the-header-menu.vue";
// import TheSocialList from "@/components/the-social-list.vue";
import TheHeaderCart from "@/components/the-header/the-header-cart.vue";
import TheHeaderProfile from "@/components/the-header/the-header-profile.vue";
import TheLogo from "@/components/the-logo.vue";
import UButton from "@/components/forms/UButton.vue";
import { checkUrl } from "@/core/helpers";
import { computed, ref } from 'vue';
import store from '@/store';
import { useRoute, useRouter } from "vue-router";
import i18n from "@/i18n";

defineOptions({
  name: 'TheHeader',
})

const route = useRoute();
const router = useRouter();

const showMobileMenu = ref(false);

const routeName = computed(() => route.name);

const routePath = computed(() => route.path);

const userFullName = computed(() => store.getters["mod_user/fullName"]);

const companyInfo = computed(() => store.state.mod_company.companyInfo);

const companyWebsite = computed(() => checkUrl((companyInfo as any)?.website));

const isAuthorized = computed(() => store.getters["mod_user/isAuthorized"]);

const login = () => {
  router.push({
    name: "login",
    query: { ...route.query },
    params: { ...route.params },
  });
};

const logout = () => {
  store
    .dispatch("mod_user/EVENT_logOut")
    .then(() => {
      showMobileMenu.value = false;
    })
    .catch((err: any) => {
      console.log("logout err", err);
    });
};
</script>

<style lang="scss" scoped>
@import "../../scss/base";
</style>
