<template>
<button
  type="button"
  :disabled="disabled"
  @click.stop.prevent="toggleDatepicker"
  @keyup.enter.stop.prevent="toggleDatepicker"
  data-qa='datepickerInput'
  :class="inputClass"
  :tabindex="tabIndex"
  class="date-input bg-white text-riverBed rounded w-full h-14 flex gap-2 items-center justify-between py-4 min-w-max"
>
  <span class="ml-4">{{ inputDate }}</span>
  <img class="mr-4" src="@/components/forms/assets/Icons/M-size/calendar.svg" alt="" />
</button>
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean },
    isOpen: { type: Boolean, required: true },
    inputDate: { type: String, default: null },
    inputDateType: { type: String, default: "check-in" },
    singleDaySelection: { type: Boolean, default: false },
    toggleDatepicker: { type: Function, required: true },
    showDatepicker: { type: Function, required: true },
    i18n: { type: [Object, Function], required: true },
  },

  computed: {
    inputClass() {
      return {
        "datepicker__input--is-active": this.isOpen && this.inputDate == null,
        "datepicker__input--single-date": this.singleDaySelection,
      };
    },
    tabIndex() {
      return this.inputDateType === "check-in" ? 0 : -1;
    },
  },
};
</script>
