<template>
  <the-header></the-header>
  
  <div class="max-w-27rem m-auto mobile:px-4" style="height: max(600px, calc(100vh - 80px - 273px - 104px));">
    <h1 class="mt-20 mobile:mt-6 font-semibold text-32px">
      {{ $t('page-login_title') }} {{ setupInfo.name }}
    </h1>
    <div class="mt-2 text-gray-600">
      <p v-if="checkCode && checkBy === $t('page-login_checked-phone')" class="w-11/12">
        {{ $t('page-login_send-code-phone-text') }} {{ inputPhone.value }}
      </p>
      <p v-else-if="checkCode && checkBy === $t('page-login_checked-email')" class="w-11/12">
        {{ $t('page-login_send-code-email-text') }} {{ inputEmail.value }}
      </p>
      <p v-else class="w-4/5">{{ $t('page-login_intro') }}</p>
    </div>

    <div class="my-14">
      <the-toggle-switch
        v-model="checkBy"
        :widthFit="true"
        :tabs="[$t('page-login_checked-phone'), $t('page-login_checked-email')]"
      ></the-toggle-switch>
    </div>

    <div v-if="checkCode">
      <UInput
        :label="$t('page-login_enter_code')"
        :inputClass="'h-56px'"
        type="text"
        inputmode="numeric"
        pattern="\\d{6}"
        required
        autofocus
        maxlength="6"
        ref="inputCodeEl"
        inputId="inputCodeEl"
        placeholder="——————"
        v-model:model-value="inputPass.value"
        :input-placeholder="$t('page-login_form-code-plh')"
        :show-actions="true"
        :text-error="inputPass.error"
        :done-success="inputPass.done"
        @send-form="requestCode"
        @input-validity="setValidity(inputPass, $event)"
        class="w-full login-page-code-input"
      ></UInput>
      <div v-if="inputPass.error" class="flex items-start mt-2 text-xs">
        <img src="@/components/forms/assets/Icons/S-size/notification/error.svg">
        <div v-if="checkBy === $t('page-login_checked-phone')" class="ml-1 text-red-600">
          <span v-if="inputPass.error === 'emptyPhone'">
            {{ $t('page-login_send-code-phone-error-text') }}
          </span>
          <span v-else>{{ $t('page-login_check-code-phone-error-text') }}</span>
        </div>
        <div v-else class="ml-1 text-red-600">
          <span v-if="inputPass.error === 'emptyEmail'">
            {{ $t('page-login_send-code-email-error-text') }}
          </span>
          <span v-else>{{ $t('page-login_check-code-email-error-text') }}</span>
        </div>
      </div>
    </div>
    <div v-else-if="checkBy === $t('page-login_checked-phone')">
      <UInputPhone
        v-model="inputPhone.value"
        class="w-full"
        :label="$t('word_phone')"
        :error="!!inputPhone.error"
        :countries="allCountries"
        :country-dial-code="inputPhone.code"
        @changeCountryCode="handleChangeCode"
      ></UInputPhone>
      <div class="font-normal text-xs mt-2 w-4/5">
        <div v-if="inputPhone.error === 'emptyPhone'" class="flex items-center">
          <img src="@/components/forms/assets/Icons/S-size/notification/error.svg" alt="">
          <span class="ml-1 text-red-600">{{ $t('page-login_input-phone-error-text') }}</span>
        </div>
        <span v-else class="text-gray-600">
          {{ $t('page-login_input-phone-text') }}
          <a
            :href="visbookUrl + 'privacy'"
            target="_blank"
            title="visbook.com/privacy ⭧"
            class="underline"
          >
            {{ $t('privacy-policy') }}
          </a>
        </span>
      </div>
    </div>
    <div v-else>
      <UInput
        :label="$t('page-login_enter_email')"
        type="textarea"
        ref="email"
        autocomplete="email"
        v-model:model-value="inputEmail.value"
        :show-actions="true"
        :done-success="inputEmail.done"
        @input-validity="setValidity(inputEmail, $event)"
        :inputClass="'h-56px'"
        class="w-full login-page-email-input"
      ></UInput>
      <div class="font-normal text-xs mt-2 w-4/5">
        <div v-if="inputEmail.error === 'emptyEmail'" class="flex items-center">
          <img src="@/components/forms/assets/Icons/S-size/notification/error.svg" alt="">
          <span class="ml-1 text-red-600">{{ $t('page-login_input-email-error-text') }}</span>
        </div>
        <div v-else-if="inputEmail.error === 'noValid'" class="flex items-center">
          <img src="@/components/forms/assets/Icons/S-size/notification/error.svg" alt="">
          <span class="ml-1 text-red-600">{{ $t('page-login_input-email-invalid-text') }}</span>
        </div>
        <span v-else class="text-gray-600">
          {{ $t('page-login_input-email-text') }}
          <a
            :href="visbookUrl + 'privacy'"
            target="_blank"
            title="visbook.com/privacy ⭧"
            class="underline"
          >
            {{ $t('privacy-policy') }}
          </a>
        </span>
      </div>
    </div>

    <div class="mt-10 mb-20 flex mobile:flex-col">
      <button
        type="button"
        ref="requestButton"
        @click="requestSwitch"
        :class="checkCode && hideResendCode ? 'w-3/5 mr-2' : 'w-full'"
        class="rounded border font-semibold bg-gray-900 text-white py-4 mobile:w-full mobile:mb-6"
      >
        {{ step === steps.GetContact ? $t('page-login_btn-send-code') : $t('page-login_btn-login') }}
      </button>
      <button
        type="button"
        ref="resendButton"
        v-show="hideResendCode"
        :disabled="!hideResendCode"
        @click="resendCode"
        class="vb-btn vb-btn--outline px-4 py-4 ml-2 w-2/5 font-semibold text-gray-900 mobile:w-full mobile:m-0"
      >
        {{ $t('page-login_resend_code') }}
      </button>
    </div>
  </div>

  <the-notification/>
  <the-footer></the-footer>
</template>

<script lang="ts" setup>
import { RequestError, in_loginPhone, i_country } from "visbook-api";
import { visbook } from '@/main';
import { deepCopy, requestVB, toRouterUrl } from "@/core/helpers";
import { i_validateCode } from "@/core/declarations";
import { loginWay_type } from "@/store/store";

import TheHeader from "@/components/the-header/the-header.vue";
import TheToggleSwitch from '@/components/forms/UToggleSwitch.vue';
import UInputPhone from '@/components/forms/UInputPhone.vue';
import UInput from '@/components/forms/UInput.vue';
import TheNotification from "@/components/the-notification.vue";
import TheFooter from "@/components/the-footer.vue";

import { computed, onMounted, ref, watch } from "vue";
import i18n from "@/i18n";
import { useRoute } from 'vue-router';
import store from "@/store";
import { emailPattern } from "../core/helpers";

enum LoginSteps {
  GetContact,
  EnterCode,
};
interface inputFields {
  value: string;
  error?: string;
  done?: boolean;
  valid: boolean;
  meta?: Record<string, never>;
  code?: string;
};

interface phoneInfoField {
  phone?: string;
  code?: string;
}

interface Data {
  ac: AbortController | null;
  checkBy: string;
  defaultInput: inputFields;
  inputEmail: inputFields;
  inputPhone: inputFields;
  inputPass: inputFields;
  step: LoginSteps;
  codeError: string | number;
};

interface Props {
  fromRouteName: string;
};

const props = withDefaults(defineProps<Props>(), {
  fromRouteName: '',
});

const ac = ref<AbortController | null>();
const checkBy = ref<string>(i18n.global.t('page-login_checked-phone'));
const defaultInput = ref<inputFields>({
  value: "",
  error: "",
  done: false,
  valid: false,
});
const inputEmail = ref<inputFields>({
  value: "",
  error: "",
  done: false,
  valid: false,
});
const inputPhone = ref<inputFields>({
  value: "",
  error: "",
  code: "47",
  done: false,
  valid: false,
});
const inputPass = ref<inputFields>({
  value: "",
  error: "",
  done: false,
  valid: false,
});
const phoneInfo = ref<phoneInfoField>({
  phone: "",
  code: "",
});
const steps = ref(LoginSteps);
const step = ref(LoginSteps.GetContact);
const codeError = ref<string | number>(0);
const disableConfirmEmail = ref<boolean>(false);
const disableConfirmPhone = ref<boolean>(false);
const checkCode = ref<boolean>(false);

const route = useRoute();

const inputCodeEl = ref<HTMLInputElement>();
const email = ref<HTMLInputElement>();

const requestButton = ref<HTMLInputElement>();
const resendButton = ref<HTMLInputElement>();

onMounted(() => {
  useAutofill();
  document.querySelector('.el-input__inner')?.focus();
  document.addEventListener('keydown', keyPressed)
});

const loginBtnDisabled = computed(() => {
  if (
    (step.value === LoginSteps.GetContact &&
      (inputEmail.value.valid || inputPhone.value.valid)) ||
    (step.value === LoginSteps.EnterCode &&
      inputPass.value.valid &&
      !disableConfirmEmail.value &&
      !disableConfirmPhone.value)
  )
    return false;
  else return true;
});

const userIsLogin = computed(() => store.getters["mod_user/isAuthorized"]);

const hideResendCode = computed(() => !(step.value !== 1 || codeError.value === 404));

const setupInfo = computed(() => store.state.mod_company.companySettings);

const visbookUrl = computed(() => {
  let url = "https://visbook.com/";
  const lang = store.state.mod_globalView.siteLanguage;
  switch (lang) {
    case "no":
      url += "no/";
      break;
    case "se":
      url += "sv/";
      break;
  }
  return url;
});

const allCountries = computed(() => store.state.mod_globalView.countries);

const handleChangeCode = (value: i_country) => {
  inputPhone.value.code = value.dialCode;
}

const useAutofill = () => {
  if ("OTPCredential" in window) {
    ac.value = new AbortController();
    navigator.credentials
      .get({
        otp: { transport: ["sms"] },
        signal: ac.value.signal,
      })
      .then((otp) => {
        if (otp) {
          const value = (otp as any).code;
          inputPass.value.value = value;
          const elem = document.getElementById(
            "inputCodeEl"
          ) as HTMLInputElement;
          elem.value = value;
          const event = document.createEvent("Event");
          event.initEvent("input", true, true);
          elem.dispatchEvent(event);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const requestSwitch = () => {
  if (step.value === LoginSteps.GetContact) {
    if (checkBy.value === i18n.global.t('page-login_checked-email')) {
      if (inputEmail.value.value) requestEmail();
      else { inputEmail.value.error = 'emptyEmail' }
    } else {
      if (inputPhone.value.value) requestSMS();
      else { inputPhone.value.error = 'emptyPhone' }
    }
  } else {
    requestCode();
  }
};

const resendCode = () => {
  step.value = LoginSteps.GetContact;
  inputPass.value = setDefaultInput();
  inputPass.value.error = '';
  requestSwitch();
};

const requestEmail = () => {
  if (!isValidEmail(inputEmail.value.value)) {
    inputEmail.value.error = 'noValid';
    return;
  }
  requestVB(visbook.SEND_email, inputEmail.value.value)
    .then(() => {
      firstRequestSuccess();
      setLoginWay(loginWay_type.EMAIL);
      checkCode.value = true;
    })
    .catch((error: RequestError) => {
      if (error.data.Email) inputEmail.value.error = error.data.Email[0];
      else inputEmail.value.error = error.message;
    });
};

const requestSMS = () => {
  useAutofill();
  phoneInfo.value.phone = inputPhone.value.value;
  phoneInfo.value.code = inputPhone.value.code || '';
  const reqData: in_loginPhone = {
    code: +phoneInfo.value.code,
    phone: +phoneInfo.value.phone,
  };
  let phoneError = '';
  requestVB(visbook.SEND_sms, reqData)
    .then(() => {
      useAutofill();
      firstRequestSuccess();
      setLoginWay(loginWay_type.PHONE);
      checkCode.value = true;
    })
    .catch((error: RequestError) => {
      if (error.data.error) inputPhone.value.error = error.data.error;
      else phoneError = error.message;
      store.dispatch("mod_globalView/SHOW_notification", {
        message: error.data.error ? error.data.error : error.message,
        type: "error"
      });
      checkCode.value = false;
    });
  inputPhone.value.error = phoneError;
};

const requestCode = () => {
  if (disableConfirmEmail.value) {
    return;
  }
  if (!inputPass.value.value || inputPass.value.value.length < 6) {
    if (checkBy.value === i18n.global.t('page-login_checked-email')) {
      inputPass.value.error = 'emptyEmail';
    } else inputPass.value.error = 'emptyPhone';
    return;
  }
  disableConfirmEmail.value = true;
  useAutofill();
  const opt: i_validateCode = {
    isEmail: checkBy.value === i18n.global.t('page-login_checked-email'),
    code: inputPass.value.value,
  };
  store
    .dispatch("mod_user/VALIDATE_code", opt)
    .then(() => {
      useAutofill();
    })
    .catch((error: RequestError) => {
      if (error.status) codeError.value = error.status;
      if (error.status === 401)
        inputPass.value.error = i18n.global.t(
          "page-login_form-code-error"
        ) as string;
      else if (error.status === 404)
        inputPass.value.error = i18n.global.t(
          "page-login_message_unreg"
        ) as string;
      else inputPass.value.error = error.message;
      store.dispatch("mod_globalView/SHOW_notification", {
        message: error.data.error,
        type: "error"
      });
    })
    .finally(() => {
      disableConfirmEmail.value = false;
    });
};

const isValidEmail = (email: string) => {
    const pattern = new RegExp(emailPattern);
    return pattern.test(email);
};

const firstRequestSuccess = () => {
  step.value = steps.value.EnterCode;
  inputEmail.value.done = true;
  inputPhone.value.done = true;
  inputPass.value = setDefaultInput();
};

const setDefaultInput = () => {
  return deepCopy(defaultInput.value);
};

const setValidity = (variable: inputFields, value: boolean) => {
  variable.valid = value;
};

const setLoginWay = (val: loginWay_type) => {
  store.dispatch("mod_globalView/SET_loginWay", val);
};

const keyPressed = (e: KeyboardEvent) => {
  if (e.key === 'Enter') requestSwitch();
};

watch(email, () => {
  document.querySelector('.el-input__inner')?.focus();
});

watch(checkBy, () => {
  checkCode.value = false;
  step.value = LoginSteps.GetContact;
  inputEmail.value.error = '';
});

watch(userIsLogin, (newVal: boolean) => {
  if (newVal) {
    store.dispatch("mod_globalView/CLOSE_modal", "login");
    if (route.name === "login") {
      toRouterUrl("home");
    }
  }
},
{
  immediate: true,
});

watch(inputEmail.value, () => {
  inputEmail.value.done = false;
  if (inputEmail.value.error) {
    const emailInput = document.querySelector('.login-page-email-input');
    emailInput?.classList.add('login-page-error');
  }
});

watch(inputPass, () => {
  if (inputPass.value.error) {
    const codeInput = document.querySelector('.login-page-code-input');
    codeInput?.classList.add('login-page-error');
  }
},
{
  deep: true,
});
</script>

<style lang="scss">
@import "../scss/base";

.login-page-code-input {
  .el-input__wrapper {
    outline: 1px solid black;
  }
  input::placeholder {
    color: black;
    font-size: 0.6rem;
    font-weight: 600;
  }
  input {
    color: black;
    letter-spacing: 2rem;
    font-size: 1.2rem;
    padding-left: 5px;
  }
}

.login-page-error {
  p {
    color: #B3261E;
  }
  .el-input__wrapper {
    background-color: #FCEEEE;
    outline: 1px solid #B3261E;
  }
  input::placeholder {
    color: #B3261E;
  }
}
</style>
