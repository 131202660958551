<template>
  <div class="vb-header-profile">
    <span class="hidden mobile:block">{{ userFullName }}</span>
    <button
      type="button"
      @click="showList = !showList"
      title="open profile menu"
      v-click-outside="()=> {this.showList = false}"
      class="vb-header-profile_cta vb-btn vb-btn--txt flex mobile:hidden"
    >
      <img
        :src="userAvatar"
        class="mr-2"
        alt=""
      >
      <span>{{ userFullName }}</span>
      <img src="@/components/forms/assets/Icons/chevron/down-white.svg" alt="">
    </button>
    <details-info :show-info="showList" class="vb-header-profile_details">
      <ul class="vb-header-profile_list">
        <li>
          <button
            type="button"
            @click="logout"
            class="vb-btn flex"
          >
            {{ $t('word_logout') }}
            <img src="@/components/forms/assets/Icons/M-size/logOut.svg" class="ml-2" alt="">
          </button>
        </li>
      </ul>
    </details-info>
  </div>
</template>

<script lang="ts">
import DetailsInfo from "@/components/different/details-info.vue";

export default {
  components: { DetailsInfo },
  data: () => ({
    showList: false,
    numOrders: 2,
  }),
  computed: {
    userFullName() {
      return this.$store.getters["mod_user/fullName"];
    },
    userAvatar() {
      return this.$store.getters["mod_user/avatar"];
    },
  },
  methods: {
    logout() {
      this.$store
        .dispatch("mod_user/EVENT_logOut")
        .then(() => {
          //??
        })
        .catch((err: any) => {
          console.log("logout err", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/base";
.vb-header-intro_profile {
  display: flex;
  align-items: center;
  span {
    margin-right: 0.5em;
  }
}
.vb-header-profile {
  position: relative;
}
.vb-header-profile_cta {
  span {
    vertical-align: middle;
  }
}
.vb-header-profile_details {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 100%;
  box-shadow: 0 1px 2px rgba(#000, 0.2);
  border-radius: $bdrs;
  background-color: $bg-page;
}
.vb-header-profile_list {
  overflow: hidden;

  color: $col-txt;
  > li {
    + li {
      border-top: $default-border;
    }
  }
  .vb-btn {
    display: flex;
    width: 100%;
    border-radius: 0;
    white-space: nowrap;
    &:hover {
      background-color: $col-border;
    }
  }
}
</style>
