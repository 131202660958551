<template>
  <div class="flex flex-col w-full">
    <p class="mb-1 text-sm font-medium text-gray-600">{{ $t('word_phone') }}</p>
    <div class="p-4 flex justify-between rounded border border-solid border-gray-200 bg-gray-50">
      <div class="flex items-center w-full">
        <span :class="`fi fi-${oldPhoneCountry} fis rounded-full mr-3`"></span>
        <span class="text-base font-normal overflow-hidden overflow-ellipsis max-w-66% update-field">
          +{{ mobileInfo.code }} {{ mobileInfo.phone }}
        </span>
      </div>
      <button @click.prevent="editPhoneNum = true">
        <span class="text-base font-medium border-b-solid border-b-black border-b-[1.5px]">{{ $t('word_edit') }}</span>
      </button>
    </div>
  </div>

  <el-dialog
    v-model="editPhoneNum"
    align-center
    :show-close="false"
    class="dialog-window min-w-[600px] max-w-[600px] sm:min-w-91% sm:max-w-91%"
  >
    <div class="p-8 mobile:p-4 text-haiti mobile:text-center">
      <div class="flex justify-between items-start mb-8">
        <div>
          <h2 class="text-2xl font-semibold mb-2">{{ $t('page-account_update-phone-title') }}</h2>
          <p class="text-sm font-normal">
            {{ $t('page-account_update-phone-subtitle') }}
            <span class="whitespace-nowrap">{{ modelMobile }}</span>
          </p>
        </div>
        <img
          src="@/components/forms/assets/Icons/M-size/x.svg"
          @click="editPhoneNum = false"
          class="cursor-pointer mobile:hidden"
        >
      </div>
      <div v-if="showCodeEmail">
        <div>
          <UInput
            :label="$t('page-account_update-phone-enter-code-input-label')"
            :inputClass="'h-56px'"
            type="text"
            inputmode="numeric"
            pattern="\\d{6}"
            required
            autofocus
            maxlength="6"
            inputId="inputCodeEl"
            placeholder="——————"
            v-model:model-value="emailCode"
            :show-actions="true"
            class="w-full update-phone-code-input"
          ></UInput>
          <div class="mt-2 text-xs text-gray-600 font-normal">
            <div v-if="emailCodeError" class="flex items-center mt-2">
              <img src="@/components/forms/assets/Icons/S-size/notification/error.svg">
              <span class="ml-1 mb-1 text-red-600">{{ emailCodeError }}</span>
            </div>
            <div v-if="resendTimer > 0 && !firstResend">
              <span>{{ $t('please-wait', { num: resendTimer }) }}</span>
            </div>
            <div v-else>
              <span>{{ $t('page-account_update-phone-enter-code-send-to') }}&nbsp;</span>
              <span>+{{ newValue.code }} {{ newValue.phone }}.</span>
              <br>
              <span>{{ $t('page-account_update-phone-resend-code-1') }}&nbsp;</span>
              <button @click.prevent="resendCode">
                <span class="text-xs font-semibold border-b-solid border-b-black border-b-[1px]">{{ $t('word_here') }}</span>
              </button>
              <span>&nbsp;{{ $t('word_or') }}&nbsp;</span>
              <button @click.prevent="showCodeEmail = false">
                <span class="text-xs font-semibold border-b-solid border-b-black border-b-[1px]">{{ $t('word_go-back') }}</span>
              </button>
              <span>&nbsp;{{ $t('page-account_update-phone-resend-code-2') }}</span>
            </div>
          </div>
          <div class="mt-8">
            <UButton
              solid
              rounded=""
              :label="$t('page-account_update-phone-update-number-button')"
              :preset="undefined"
              :border-width="1"
              :color="'gray-900'"
              :text-color="'white'"
              :font-weight="600"
              :padding="'px-6 py-4'"
              class="w-full"
              @click.prevent="confirm"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          <UInputPhone
            v-model="newValue.phone"
            class="w-full"
            :label="$t('page-account_update-phone-input-title')"
            :countries="allCountries"
            :error="!!newValue.error"
            :country-dial-code="newValue.code"
            @changeCountryCode="handleChangeCode"
          ></UInputPhone>
          <div v-if="newValue.error" class="flex items-center mt-2">
            <img src="@/components/forms/assets/Icons/S-size/notification/error.svg">
            <span class="ml-1 text-red-600">{{ newValue.error }}</span>
          </div>
          <p v-else class="mt-2 text-xs text-gray-600 font-normal">{{ $t('page-account_update-phone-send-code-text') }}</p>
        </div>
        <div class="mt-8">
          <UButton
            solid
            rounded=""
            :label="$t('page-account_update-phone-send-code-button')"
            :preset="undefined"
            :border-width="1"
            :color="'gray-900'"
            :text-color="'white'"
            :font-weight="600"
            :padding="'px-6 py-4'"
            class="w-full"
            @click.prevent="verifyMobile"
          />
        </div>
      </div>
    </div>
  </el-dialog>

  <UInputPhone
    v-show="false"
    v-model="newValue.phone"
    :countries="allCountries"
    :country-dial-code="newValue.code"
    @changeCountryCode="handleChangeCode"
  ></UInputPhone>
</template>

<script lang="ts">
import { RequestError, in_loginPhone, i_country } from 'visbook-api';

import UInputPhone from '@/components/forms/UInputPhone.vue';
import UButton from '@/components/forms/UButton.vue';
import UInput from '@/components/forms/UInput.vue';

import { requestVB } from "@/core/helpers";
import i18n from "@/i18n";

export default {
  components: {
    UInputPhone,
    UButton,
    UInput,
  },
  emits: ['confirm-mobile'],
  props: {
    modelMobile: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    mobileInfo: {
      phone: '',
      code: '',
      country: '',
    },
    showCodeEmail: false,
    newValue: {
      phone: '',
      code: '',
      error: '',
      saved: true,
    },
    oldPhoneCountry: '',
    emailCode: '',
    emailCodeError: '',
    editPhoneNum: false,
    resendTimer: 0,
    firstResend: true,
    interval: undefined,
  }),
  computed: {
    allCountries(): i_country[] {
      return this.$store.state.mod_globalView.countries;
    },
  },
  mounted() {
    this.newValue.phone = this.mobileInfo.phone;
    this.newValue.code = this.mobileInfo.code;
  },
  watch: {
    modelMobile: {
      handler(mobile: string) {
        this.mobileInfo.code = this.modelMobile.split(" ")[0].replace("+", "");
        const phoneNumberArr = this.modelMobile.split(" ");
        phoneNumberArr.shift();
        this.mobileInfo.phone = phoneNumberArr.join(" ");
      },
      immediate: true
    },
    emailCodeError: {
      handler() {
        if(this.emailCodeError) {
          const codeInput = document.querySelector('.update-phone-code-input');
          codeInput?.classList.add('update-phone-input-error');
        }
      },
    },
    emailCode: {
      handler() {
        this.emailCodeError = '';
      },
    }
  },
  methods: {
    confirm() {
      if(this.emailCode.length < 6) {
        this.emailCodeError = i18n.global.t('page-login_send-code-phone-error-text');
        return;
      }
      this.$emit('confirm-mobile', {
        value: `+${this.newValue.code} ${this.newValue.phone}`,
        code: this.emailCode
      });
      this.showCodeEmail = false;
      this.editPhoneNum = false;
      this.newValue.saved = true;
    },
    verifyMobile() {
      if(this.newValue.code === this.mobileInfo.code && this.newValue.phone === this.mobileInfo.phone) {
        this.newValue.error = i18n.global.t('page-account_update-phone-same-numbers');
        return;
      }
      if(!this.newValue.phone.length) {
        this.newValue.error = i18n.global.t('page-account_update-phone-invalid-number');
        return;
      }
      const reqData: in_loginPhone = {
        code: +this.newValue.code,
        phone: +(this.newValue.phone.replaceAll(' ', ''))
      };
      requestVB(this.$visbook.SEND_sms, reqData as any)
          .then(() => {
            this.showCodeEmail = true;
            this.startResendTimer();
            this.newValue.error = '';
          })
          .catch((error: RequestError) => {
            this.newValue.error = error.data.error ? error.data.error : error.message;
            if (error.status === 401) this.showError(this.$t('page-login_form-code-error') as string);
            else if (error.status === 404) this.showError(this.$t('page-login_message_unreg') as string);
            else error.data.error ? this.showError(error.data.error) : this.showError(error.message);
          })
    },
    resendCode() {
      if (this.firstResend) {
        this.firstResend = false;
        return;
      }
      if(this.resendTimer === 0) {
        this.verifyMobile();
      } else {
        this.startResendTimer();
      }
    },
    startResendTimer() {
      this.resendTimer = 30;
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if(this.resendTimer > 0) {
          this.resendTimer--;
        }
      }, 1000);
    },
    showError(message: string) {
      this.$store.dispatch('mod_globalView/SHOW_notification', {
        message,
        type: 'error'
      })
    },
    handleChangeCode(value: i_country) {
      this.newValue.code = value.dialCode;
      if (this.newValue.saved) {
        this.oldPhoneCountry = value.iso2;
      }
      this.newValue.saved = false;
    }
  }
}
</script>

<style lang="scss">
//@import "../../scss/base";

.update-phone-code-input {
  .el-input__wrapper {
    outline: 1px solid black;
  }
  input::placeholder {
    color: black;
    font-size: 0.6rem;
    font-weight: 600;
  }
  input {
    color: black;
    letter-spacing: 2rem;
    font-size: 1.2rem;
    padding-left: 5px;
  }
}
.update-phone-input-error {
  p {
    color: #B3261E;
  }
  .el-input__wrapper {
    background-color: #FCEEEE;
    outline: 1px solid #B3261E;
  }
  input::placeholder {
    color: #B3261E;
  }
}
</style>
