<template>
  <button
    :style="styles"
    :class="`relative flex items-center justify-center cursor-pointer outline-none box-border ${props.padding}`"
    @click="handleClick"
    :disabled="props.disabled"
  >
    <!-- <slot name="before" v-bind="{ label }">
      <div v-if="beforeIcon">
        <UIcon
          class="absolute"
          :value="beforeIcon"
          :solid="iconStyle === 'solid'"
          :size="iconSize"
        />
      </div>
    </slot> -->
    <!-- <slot name="label"> -->
      <span
        :style="labelStyles"
        class="relative top-px selection:bg-none"
      >
        {{ label }}
      </span>
    <!-- </slot> -->
    <!-- <slot name="after">
      <div v-if="afterIcon">
        <UIcon
          class="absolute right-4 top-1/2 -translate-y-1/2"
          :value="afterIcon"
          :solid="iconStyle === 'solid'"
          :size="iconSize"
        />
      </div>
    </slot> -->
  </button>
</template>

<script setup lang="ts">
import { rounded as _rounded, colors } from "@/components/forms/utils/defaults";
// import UIcon from "./UIcon.vue"
import presets, { Props } from "@/components/forms/presets/button";
import { ref, computed } from "vue";

const emptyGray = "#e5e7eb";
const emptyGrayText = "#737373";

const props = withDefaults(defineProps<Props>(), {
  preset: "solid",
  disabled: false,
})

const emit = defineEmits<{
  click: [event: MouseEvent]
}>();

const getPreset = (preset: string): Props => {
  const choosenPreset = (presets as any)[preset];
  Object.keys(props).forEach((key) => {
    if (props[key as keyof Props] !== undefined) {
      choosenPreset[key] = props[key as keyof Props];
    }
  });
  return choosenPreset;
}

const getColorString = (color: string): string => {
  if (color.indexOf("#") !== -1) {
    return color;
  } else if (color.indexOf("-") !== -1) {
    const colorParts = color.split("-");
    return colors[colorParts[0]][Number(colorParts[1])];
  } else {
    return colors[color];
  }
};

const handleClick = (event: MouseEvent) => {
  if (!props.disabled) emit('click', event)
  else event.preventDefault()
};

const padding = computed<string>(() => {
  switch (props.size) {
    case "xl":
      return "8px 24px"
    case "lg":
      return "6px 18px"
    case "":
      return "5px 14px"
    case "sm":
      return "4px 8px"
    case "xs":
      return "3px 6px"
    default:
      return ""
  }
});

const color = computed<string>(() =>
  props.disabled
    ? getColorString(emptyGrayText)
    : getColorString(props.color as string)
);

const rounded = computed<string>(() => {
  const value = props.rounded === '' ? "DEFAULT" : props.rounded;
  return _rounded[value as string];
});

const background = computed<string>(() => props.solid ? color.value : "");

const border = computed<string>(() =>
  `${props.borderWidth}px solid ${
    props.disabled ? getColorString(emptyGray) : getColorString(props.borderColor || color.value)
  }`
);

const textColor = computed<string>(() => getColorString(props.textColor as string));

const styles = computed(() => {
  if (props.padding) {
    return {
      border: border.value,
      borderRadius: rounded.value,
      minWidth: `${props.minWidth}px`,
      backgroundColor: background.value
    }
  }
  return {
    padding: padding.value,
    border: border.value,
    borderRadius: rounded.value,
    minWidth: `${props.minWidth}px`,
    backgroundColor: background.value
  }
});

const labelStyles = computed(() => ({
  fontWeight: `${props.fontWeight}`,
  fontSize: `${props.fontSize}px`,
  color: getColorString(textColor.value)
}));
</script>